const binaries = [
  '<tspan x="-1" y="11">00100010 01001001 00100111 01101100 01101100 00100000 01100010 01100101 00100000 01100010 </tspan> <tspan x="-1" y="37">01100001 01100011 01101011 00101110 00100010 00100000 00101101 00100000 01101000 01110100 </tspan> <tspan x="-1" y="63">01110100 01110000 01110011 00111010 00101111 00101111 01100010 01101001 01110100 00101110 </tspan> <tspan x="-1" y="89">01101100 01111001 00101111 00110010 01001011 01010000 01101010 01110101 01010110 01100100 </tspan> <tspan x="-1" y="115">00100010 01001001 00100111 01101100 01101100 00100000 01100010 01100101 00100000 01100010 </tspan> <tspan x="-1" y="141">01100001 01100011 01101011 00101110 00100010 00100000 00101101 00100000 01101000 01110100 </tspan> <tspan x="-1" y="167">01110100 01110000 01110011 00111010 00101111 00101111 01100010 01101001 01110100 00101110 </tspan> <tspan x="-1" y="193">01101100 01111001 00101111 00110010 01001011 01010000 01101010 01110101 01010110 01100100 </tspan> <tspan x="-1" y="219">00100010 01001001 00100111 01101100 01101100 00100000 01100010 01100101 00100000 01100010 </tspan> <tspan x="-1" y="245">01100001 01100011 01101011 00101110 00100010 00100000 00101101 00100000 01101000 01110100 </tspan> <tspan x="-1" y="271">01110100 01110000 01110011 00111010 00101111 00101111 01100010 01101001 01110100 00101110 </tspan> <tspan x="-1" y="297">01101100 01111001 00101111 00110010 01001011 01010000 01101010 01110101 01010110 01100100 </tspan> <tspan x="-1" y="323">00100010 01001001 00100111 01101100 01101100 00100000 01100010 01100101 00100000 01100010 </tspan> <tspan x="-1" y="349">01100001 01100011 01101011 00101110 00100010 00100000 00101101 00100000 01101000 01110100 </tspan> <tspan x="-1" y="375">01110100 01110000 01110011 00111010 00101111 00101111 01100010 01101001 01110100 00101110 </tspan>',
  '<tspan x="-1" y="11">00100010 01011001 01101111 01110101 00100000 01101101 01100101 01110010 01100101 01101100 </tspan> <tspan x="-1" y="37">01111001 00100000 01100001 01100100 01100001 01110000 01110100 01100101 01100100 00100000 </tspan> <tspan x="-1" y="63">01110100 01101111 00100000 01110100 01101000 01100101 00100000 01100100 01100001 01110010 </tspan> <tspan x="-1" y="89">01101011 00101100 00100000 01001001 00100000 01110111 01100001 01110011 00100000 01100010 </tspan> <tspan x="-1" y="115">01101111 01110010 01101110 00100000 01101001 01101110 01110100 01101111 00100000 01101001 </tspan> <tspan x="-1" y="141">01110100 00100010 00100000 00101101 00100000 01101000 01110100 01110100 01110000 01110011 </tspan> <tspan x="-1" y="167">00111010 00101111 00101111 01100010 01101001 01110100 00101110 01101100 01111001 00101111 </tspan> <tspan x="-1" y="193">00110010 01001011 01010000 01101010 01110101 01010110 01100100 00100010 01011001 01101111 </tspan> <tspan x="-1" y="219">01110101 00100000 01101101 01100101 01110010 01100101 01101100 01111001 00100000 01100001 </tspan> <tspan x="-1" y="245">01100100 01100001 01110000 01110100 01100101 01100100 00100000 01110100 01101111 00100000 </tspan> <tspan x="-1" y="271">01110100 01101000 01100101 00100000 01100100 01100001 01110010 01101011 00101100 00100000 </tspan> <tspan x="-1" y="297">01001001 00100000 01110111 01100001 01110011 00100000 01100010 01101111 01110010 01101110 </tspan> <tspan x="-1" y="323">00100000 01101001 01101110 01110100 01101111 00100000 01101001 01110100 00100010 00100000 </tspan> <tspan x="-1" y="349">00101101 00100000 01101000 01110100 01110100 01110000 01110011 00111010 00101111 00101111 </tspan> <tspan x="-1" y="375">01100010 01101001 01110100 00101110 01101100 01111001 00101111 00110010 01001011 01010000 </tspan>',
  '<tspan x="-1" y="11">00100010 01001110 01101111 00101100 00100000 01001001 00100000 01100001 01101101 00100000 </tspan> <tspan x="-1" y="37">01111001 01101111 01110101 01110010 00100000 01100110 01100001 01110100 01101000 01100101 </tspan> <tspan x="-1" y="63">01110010 00100010 00100000 00101101 00100000 01101000 01110100 01110100 01110000 01110011 </tspan> <tspan x="-1" y="89">00111010 00101111 00101111 01100010 01101001 01110100 00101110 01101100 01111001 00101111 </tspan> <tspan x="-1" y="115">00110010 01001011 01010000 01101010 01110101 01010110 01100100 00100010 01001110 01101111 </tspan> <tspan x="-1" y="141">00101100 00100000 01001001 00100000 01100001 01101101 00100000 01111001 01101111 01110101 </tspan> <tspan x="-1" y="167">01110010 00100000 01100110 01100001 01110100 01101000 01100101 01110010 00100010 00100000 </tspan> <tspan x="-1" y="193">00101101 00100000 01101000 01110100 01110100 01110000 01110011 00111010 00101111 00101111 </tspan> <tspan x="-1" y="219">01100010 01101001 01110100 00101110 01101100 01111001 00101111 00110010 01001011 01010000 </tspan> <tspan x="-1" y="245">01101010 01110101 01010110 01100100 00100010 01001110 01101111 00101100 00100000 01001001 </tspan> <tspan x="-1" y="271">00100000 01100001 01101101 00100000 01111001 01101111 01110101 01110010 00100000 01100110 </tspan> <tspan x="-1" y="297">01100001 01110100 01101000 01100101 01110010 00100010 00100000 00101101 00100000 01101000 </tspan> <tspan x="-1" y="323">01110100 01110100 01110000 01110011 00111010 00101111 00101111 01100010 01101001 01110100 </tspan> <tspan x="-1" y="349">00101110 01101100 01111001 00101111 00110010 01001011 01010000 01101010 01110101 01010110 </tspan> <tspan x="-1" y="375">01100100 </tspan>',
  '<tspan x="-1" y="11">00100010 01001001 00100111 01101100 01101100 00100000 01101101 01100001 01101011 01100101 </tspan> <tspan x="-1" y="37">00100000 01101000 01101001 01101101 00100000 01100001 01101110 00100000 01101111 01100110 </tspan> <tspan x="-1" y="63">01100110 01100101 01110010 00100000 01101000 01100101 00100000 01100011 01100001 01101110 </tspan> <tspan x="-1" y="89">00100111 01110100 00100000 01110010 01100101 01100110 01110101 01110011 01100101 00100010 </tspan> <tspan x="-1" y="115">00100000 00101101 00100000 01101000 01110100 01110100 01110000 01110011 00111010 00101111 </tspan> <tspan x="-1" y="141">00101111 01100010 01101001 01110100 00101110 01101100 01111001 00101111 00110010 01001011 </tspan> <tspan x="-1" y="167">01010000 01101010 01110101 01010110 01100100 00100010 01001001 00100111 01101100 01101100 </tspan> <tspan x="-1" y="193">00100000 01101101 01100001 01101011 01100101 00100000 01101000 01101001 01101101 00100000 </tspan> <tspan x="-1" y="219">01100001 01101110 00100000 01101111 01100110 01100110 01100101 01110010 00100000 01101000 </tspan> <tspan x="-1" y="245">01100101 00100000 01100011 01100001 01101110 00100111 01110100 00100000 01110010 01100101 </tspan> <tspan x="-1" y="271">01100110 01110101 01110011 01100101 00100010 00100000 00101101 00100000 01101000 01110100 </tspan> <tspan x="-1" y="297">01110100 01110000 01110011 00111010 00101111 00101111 01100010 01101001 01110100 00101110 </tspan> <tspan x="-1" y="323">01101100 01111001 00101111 00110010 01001011 01010000 01101010 01110101 01010110 01100100 </tspan> <tspan x="-1" y="349">00100010 01001001 00100111 01101100 01101100 00100000 01101101 01100001 01101011 01100101 </tspan> <tspan x="-1" y="375">00100000 01101000 01101001 01101101 00100000 01100001 01101110 00100000 01101111 </tspan>',
  '<tspan x="-1" y="11">00100010 01010111 01101000 01111001 00100000 01110011 01101111 00100000 01110011 01100101 </tspan> <tspan x="-1" y="37">01110010 01101001 01101111 01110101 01110011 00111111 00100010 00100000 00101101 00100000 </tspan> <tspan x="-1" y="63">01101000 01110100 01110100 01110000 01110011 00111010 00101111 00101111 01100010 01101001 </tspan> <tspan x="-1" y="89">01110100 00101110 01101100 01111001 00101111 00110010 01001011 01010000 01101010 01110101 </tspan> <tspan x="-1" y="115">01010110 01100100 00100010 01010111 01101000 01111001 00100000 01110011 01101111 00100000 </tspan> <tspan x="-1" y="141">01110011 01100101 01110010 01101001 01101111 01110101 01110011 00111111 00100010 00100000 </tspan> <tspan x="-1" y="167">00101101 00100000 01101000 01110100 01110100 01110000 01110011 00111010 00101111 00101111 </tspan> <tspan x="-1" y="193">01100010 01101001 01110100 00101110 01101100 01111001 00101111 00110010 01001011 01010000 </tspan> <tspan x="-1" y="219">01101010 01110101 01010110 01100100 00100010 01010111 01101000 01111001 00100000 01110011 </tspan> <tspan x="-1" y="245">01101111 00100000 01110011 01100101 01110010 01101001 01101111 01110101 01110011 00111111 </tspan> <tspan x="-1" y="271">00100010 00100000 00101101 00100000 01101000 01110100 01110100 01110000 01110011 00111010 </tspan> <tspan x="-1" y="297">00101111 00101111 01100010 01101001 01110100 00101110 01101100 01111001 00101111 00110010 </tspan> <tspan x="-1" y="323">01001011 01010000 01101010 01110101 01010110 01100100 00100010 01010111 01101000 01111001 </tspan> <tspan x="-1" y="349">00100000 01110011 01101111 00100000 01110011 01100101 01110010 01101001 01101111 01110101 </tspan> <tspan x="-1" y="375">01110011 00111111 00100010 00100000 00101101 00100000 01101000 01110100 01110100 </tspan>'
];

function appendBinariesTo(element) {
  const svtText = binaries[Math.floor(Math.random() * binaries.length)];
  const svg = `<svg width="959" height="377" xmlns="http://www.w3.org/2000/svg"><text fill="#E9E9E9" fill-rule="evenodd" font-family="Source Code Pro" font-weight="500" font-size="18">${svtText}</text></svg>`;
  element.insertAdjacentHTML("afterbegin", svg);
}

export default appendBinariesTo;
