import "../css/main.css";
import appendBinariesTo from "./binaries.js";
const $ = document.querySelector.bind(document);

function loadHeaderBackdrop() {
  const backdrop = $(".js-header-backdrop");

  appendBinariesTo(backdrop);
}

// loadHeaderBackdrop();
